.analytics-box-container {
    width: 100%;
    gap: 55px;
    padding: 40px 0px;
    .realised-turnover {
        h1 {
            background: -webkit-linear-gradient(#57DA65, #47A66B);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        h4 {
            font-size: 24px;
            color: #56D765;
        }
    }
    .cost-of-materials {
        h1 {
            background: -webkit-linear-gradient(#09ADFF, #5272F4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        h4 {
            font-size: 24px;
            color: #2C90FA;
        }
    }
    .stock-value {
        h1 {
            background: -webkit-linear-gradient(#FF6F00, #FFB400);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        h4 {
            font-size: 24px;
            color: #FFB301;
        }
    }
    .margin {
        h1 {
            background: -webkit-linear-gradient(#873CFC, #C7A4FF);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        h4 {
            font-size: 24px;
            color: #8940FB;
        }
    }
}

.analytics-box {
    width: calc(25% - 50px);
    height: 190px;
    flex-shrink: 1;
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
       
        font-size: 70px !important;
        font-weight: 1000;
        .currency {
            font-size: 40px;
        }
    }
   
}

.card-spinner-container {
    top: 55% !important;
    width: 70vw !important;
}

.card-body {
    position: relative;
    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}