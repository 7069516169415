@import "../../assets/scss/variables";

@media screen and (max-width: 1599px) and (min-height: 831px) {
  .dashboard.inventories-container {
    height: calc(100vh - 170px) !important;
  }
}

.labo-container {
  display: unset !important;

  .divider {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;
    .navbtns {
      width: 50%;
      li {
        border-bottom: none;
        .nav-link.active {
          color: #8232FF;
        }
      }
    }
  }
  .leftcontent-labo {
    .card-navbtns {
      /* border-bottom: 1px solid #e0e0e0; */
      .navbtns {
        margin-bottom: -1px;
      }
    }
  }
  .rightcontent {
    .card {
      height: 100%;
      background: $fullsoon;
      border-radius: 10px;
      .card-body {
        .fltr-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;
          label {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
          }
          button {
            background: transparent;
            border: 0;
            color: #fff;
            border-bottom: 1px solid #fff;
            padding: 0px 3px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
        }

        .datepicker-wrapper {
          span {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
          .datepicker-wrapper-img {
            display: flex;
            justify-content: flex-end;
          }
          img {
            cursor: pointer;
          }
          .date-picker {
            background: transparent;
            border: 0;
            color: #fff;
            font-weight: bold;
            width: 100%;
            outline: none;
          }
        }

        .react-datepicker-wrapper {
          width: 60%;
        }
        .hr-separator {
          height: 0.5px;
          margin: 0.5rem 0;
          color: #f3f4fb;
        }
        label {
          cursor: pointer;
          &:hover {
            color: #fff !important;
          }
        }
        color: #fff;
        h1 {
          font-weight: bold;
          font-size: 21px;
          line-height: 29px;
        }
        .action-btns {
          bottom: 20px;
          display: flex;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          button {
            background: #f1f3f4;
            border-radius: 5px;
            width: 221px;
            height: 40px;
            width: 102px;
            height: 40px;
            border: 0;
            font-size: 16px;
            font-weight: bold;
            &.close-btn {
              color: #8b8f94;
            }
            &.apply-btn {
              color: #6353ea;
            }
          }
        }
        .hide-filter {
          display: flex;
          justify-content: end;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          margin-top: 5px;
          text-decoration: underline;
          cursor: pointer;
          img {
            margin-left: 5px;
          }
        }
      }
      .checkbox {
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        input {
          // display: none; /* Hide the default checkbox */
          padding-right: 10px;
        }
        span {
          height: 15px;
          width: 5px;
          display: inline-block;
          position: relative;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 2px;
          margin: 0px !important;
        }
        // [type="checkbox"]:checked + span:before {
        //   content: "\2714";
        //   position: absolute;
        //   top: -2px;
        //   left: 1.5px;
        //   font-size: 14px;
        // }
      }
    }
  }

  .card-pie-chart {
    display: flex;
    flex-direction: row;
    min-height: 100px;
    height: inherit;
    justify-content: space-between;
    max-height: calc(100% - 50px);
  }

  .after-divider-container {
    display: flex;
    height: calc(100% - 110px);
  }

  @media screen and (min-width: 1600px) {
    .after-divider-container {
      height: calc(100% - 51px);
    }
  }

  @media screen and (min-width: 1600px) and (min-height: 850px) and (max-height: 1000px) {
    .main-container-competitiveset {
      .pie-container {
        height: 50% !important;
      }
    }
  }

  @media screen and (max-width: 1599px) {
    .show-below-800 {
      height: calc(100% - 270px) !important;
    }
    .show-above-800 {
      height: calc(100% - 255px) !important;
    }
    .after-divider-container {
      height: calc(100% - 50px) !important;
    }
  }

  @media screen and (min-width: 1600px) and (min-height: 851px) {
    .show-above-800 {
      height: calc(100% - 240px) !important;
    }
  }

  @media screen and (min-width: 1600px) and (max-height: 850px) {
    .show-below-800 {
      height: calc(100% - 300px) !important;
    }
  }

  @media screen and (max-height: 850px) {
    .main-container-competitiveset {
      .pie-sales-navbtns {
        display: block;
      }
      .show-below-800 {
        display: block;
      }
      .show-above-800 {
        display: none;
      }
      .pie-container {
        height: 100% !important;
      }
      .card-pie-chart {
        max-height: calc(100% - 50px);
      }
    }
  }

  @media screen and (max-height: 640px) {
    .main-container-competitiveset {
      .show-below-800 {
        height: calc(100% - 140px) !important;
      }
      .card-pie-chart {
        max-height: calc(100% - 10px);
      }

      .pie-container {
        margin-bottom: 20px;
        height: 55% !important;
        .highcharts-title {
          font-size: 10px !important;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media screen and (min-height: 851px) {
    .main-container-competitiveset {
      .show-above-800 {
        display: block;
      }
      .show-below-800 {
        display: none;
      }
    }
  }

  .main-container-competitiveset {
    .top-flop-container {
      .top-flop {
        .top-flop-item {
          @media screen and (max-height: 785px) {
            &:nth-last-child(2) {
              display: flex !important;
            }
          }
          @media screen and (max-height: 830px) {
            &:nth-last-child(1) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1921px) and (max-height: 821px) {
            &:nth-last-child(2) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1921px) and (max-height: 970px) {
            &:nth-last-child(1) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1600px) and (min-height: 850px) and (max-height: 920px) {
            &:nth-last-child(1) {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

.productionPlanning-container {
  height: 100vh !important;
  display: flex;
}

.labo-container {
  .leftcontent-stock {
    padding-right: 0px !important;
    .custom-table .tablescroll {
      height: calc(100vh - 290px) !important;
    }
    @media screen and (max-width: 1599px) {
      .card {
        height: calc(100vh - 150px);
      }
      .tablescroll {
        height: calc(100vh - 315px) !important;
      }
    }
    @media screen and (min-width: 1600px) {
      min-height: calc(100vh - 190px);
      .card {
        height: calc(100vh - 150px);
      }
      .tablescroll {
        height: calc(100vh - 350px) !important;
      }
    }
    @media screen and (min-width: 1600px) and (min-height: 800px) {
      min-height: calc(100vh - 190px);
      .card {
        height: calc(100vh - 190px);
      }
      .tablescroll {
        height: calc(100vh - 400px) !important;
      }
    }

    .place-order-btn {
      width: fit-content;
      height: 40px;
      left: 300px;
      background: $fullsoon;
      border-radius: 5px;
      color: #fff;
      border: 0;
      font-weight: bold;
      font-size: 15px;
      padding: 9px 31px;
    }
    .text-purple {
      color: $fullsoon !important;
    }
    .text-good {
      color: #14c25a !important;
    }
    .text-soon {
      color: #ffb11b !important;
    }
    .text-out {
      color: #8b8f94 !important;
    }
    .dropdown-toggle::after {
      margin-left: 0.65em;
    }
    .selected-prod-div {
      label {
        &:first-child {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          &:hover {
            color: inherit !important;
          }
        }
        &:nth-child(2) {
          color: #8b8f94;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  .rightcontent {
    .card {
      height: 100%;
      background: $fullsoon;
      border-radius: 10px;
      .card-body {
        .fltr-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;
          label {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
          }
          button {
            background: transparent;
            border: 0;
            border-bottom: 1px solid #fff;
            padding: 0px 3px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
        }

        .datepicker-wrapper {
          span {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
          .datepicker-wrapper-img {
            display: flex;
            justify-content: flex-end;
          }
          img {
            cursor: pointer;
          }
          .date-picker {
            background: transparent;
            border: 0;
            color: #fff;
            font-weight: bold;
            width: 100%;
            outline: none;
          }
        }

        .react-datepicker-wrapper {
          width: 60%;
        }
        .hr-separator {
          height: 0.5px;
          margin: 0.5rem 0;
          color: #f3f4fb;
        }
        label {
          cursor: pointer;
          &:hover {
            color: #000 !important;
          }
        }
        color: #fff;
        h1 {
          font-weight: bold;
          font-size: 21px;
          line-height: 29px;
        }
        .action-btns {
          bottom: 20px;
          display: flex;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          button {
            background: #f1f3f4;
            border-radius: 5px;
            width: 221px;
            height: 40px;
            width: 102px;
            height: 40px;
            border: 0;
            font-size: 16px;
            font-weight: bold;
            &.close-btn {
              color: #8b8f94;
            }
            &.apply-btn {
              color: #6353ea;
            }
          }
        }
        .reset-action-btns {
          bottom: 20px;
          display: flex;
          position: absolute;
          left: 50%;
          width: 100%;
          transform: translate(-50%, 0);
          button {
            background: #f1f3f4;
            border-radius: 5px;
            width: 221px;
            height: 40px;
            width: 102px;
            height: 46px;
            border: 0;
            font-size: 16px;
            font-weight: bold;
            &.close-btn2 {
              color: #8b8f94;
              width: 100%;
            }
            &.apply-btn {
              color: #6353ea;
            }
          }
        }
        .hide-filter {
          display: flex;
          justify-content: end;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          margin-top: 5px;
          text-decoration: underline;
          cursor: pointer;
          img {
            margin-left: 5px;
          }
        }
      }
      .checkbox {
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        input {
          // display: none; /* Hide the default checkbox */
          padding-right: 10px;
        }
        span {
          height: 10px;
          width: 5px;
          display: inline-block;
          position: relative;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 2px;
          margin: 0px !important;
        }
        // [type="checkbox"]:checked + span:before {
        //   content: "\2714";
        //   position: absolute;
        //   top: -2px;
        //   left: 1.5px;
        //   font-size: 14px;
        // }
      }
    }
  }
}

.text-accept {
  color: #00b087 !important;
  margin-right: 3px;
}

.text-refuse {
  color: #df0404 !important;
  margin-right: 3px;
}

.text-send {
  color: #8232ff !important;
  margin-right: 3px;
}


  .add-ingredient-labo {
    .modal-dialog {
      max-width: 1112px;
      .modal-content {
        height: 730px;
        .modal-body {
          display: flex;
          .p-0.container {
            display: flex;
            form {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
          }
        }
      }
    }
   
  }

  .sales-forecast-wrapper{
    position: relative;
    width: 100%;
    margin-top: 10px;
  }
  
  .sales-flex-wrapper{
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .period-label{
    font-size: 12px;
  }

  .compare-checkbox {
    .dropdown {
      button{
        border-radius: 10px !important;
      }
    }
  }

  .meals-dropdown {
    .css-b62m3t-container {
      .css-13cymwt-control {
        border-radius: 10px;
      }
    }
  }

  .sales-forecast {
    td {
      height: 80px !important;
    }
  }

  .forecast-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }