@import "../../../assets/scss/variables";


.validate-btn {
  width: fit-content;
  height: auto;
  left: 300px;
  background: $fullsoon;
  border-radius: 5px;
  color: #fff;
  border: 0;
  font-weight: bold;
  font-size: 11px;
  padding: 9px 18px;
}