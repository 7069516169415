@import "assets/scss/variables";

.my-ingredients {
  height: calc(100% - 165px);
  .sort-container {
    text-align: end;
    img {
      height: 38px;
      cursor: pointer;
    }
  }
  .table-container {
    height: calc(100vh - 290px);
  }
}
.add-btn-container {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  order: 1;
  flex-grow: 0;
  .add-btn-icon {
    margin-right: 10px;
  }
}

.search-input {
  height: 38px;
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 15px;
  &:focus-visible {
    outline: none;
  }
}

.allergens-container {
  .allergens-badge {
    background-color: #e0e0e0 !important;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    padding: 9px 27px;
    align-items: center;
    text-align: center;
    color: #8b8f94;
    height: 37px;
    margin-bottom: 13px;
    cursor: pointer;
    margin: 0px 10px 10px 0px;

    // &:not(:first-child) {
    //   margin-left: 10px;
    // }

    &.active {
      background-color: $purple !important;
      color: $white !important;
    }
  }
}
.sort-filter {
  button {
    height: 38px;
  }
}
.customSelect {
  height: 33px !important;
}

.add-meals {
  .modal-header {
    border-bottom: 0px solid #dee2e6 !important;
  }
  .modal-title {
    font-style: normal;
    font-weight: bold !important;
    font-size: 21px !important;
    line-height: 29px !important;
    color: #000000 !important;
  }
  .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    width: 100%;
    overflow: auto;
    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      outline: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 40px;
      background-color: #eeeeee;
    }
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      width: 6px;
      height: 6px;
      border-radius: 40px;
    }

    .input-title {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      margin-bottom: 5px;
      margin-top: 10px !important;
    }
    .custom-input {
      // height: 37px;
      background: #ffffff;
      // border: 0.5px solid #e0e0e0 !important;
      box-sizing: border-box !important;
      // border-radius: 2px !important;
      border-radius: 10px !important;
      border: .1px solid #e0e0e0 !important;
      height: 43px !important;
          &:focus {
        box-shadow: none;
      }
    }
    .dropzone {
      text-align: center;
      padding: 20px;
      color: #bdbdbd;
      margin-bottom: 12px;
      background: rgba(99, 83, 234, 0.1);
      border: 1px dashed #6353ea;
      box-sizing: border-box;
      border-radius: 10px;
      height: 142px;
      p {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #8b8f94;
        padding-top: 40px;
      }
    }

    .photo-container {
      .files-list {
        li {
          height: 48px;
          background: #ffffff;
          border: 0.5px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 15px;
          padding-top: 6px !important;
          img {
            width: 20px;
            height: 20px;
            margin-top: 6px;
            cursor: pointer;
          }
          img.eye {
            width: 15px;
            height: 10px;
          }
          span {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #000000;
            &.size {
              font-size: 12px;
            }
          }
        }
      }
    }

    .bottom-container {
      padding: 0.5rem 0.75rem !important;
    }
    .btn-add-ingredient {
      padding: 5px 15px;
      height: 35px;
      width: 107px;
      border: 1px solid #6353ea;
      box-sizing: border-box;
      border-radius: 5px;
      color: #6353ea;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      font-family: Nunito Sans;
      font-style: normal;
      img {
        margin: 0px 10px 2px 0px;
      }
    }
    .add-ingredient-modal {
      width: auto !important;
    }

    .total-cost-container {
      font-family: Nunito Sans;
      font-style: normal;
      text-align: end;
      .total-cost-text {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #8b8f94;
      }
      .total-cost {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }
      .per-servings {
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        color: #8b8f94;
      }
    }
  }
}
.upload-container {
  font-family: Nunito Sans;
  font-style: normal;
  color: #000000;
  label {
    &:hover {
      color: inherit !important;
    }
  }
  div.heading {
    label {
      font-weight: bold;
      font-size: 21px;
      line-height: 29px;
      text-align: center;
    }
  }
  div.subheading {
    label {
      line-height: 22px;
      text-align: center;
    }
  }
  button {
    width: 20rem;
  }
}

.meal-name-select {
  z-index: 100;
}

.meal-modal-title {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.meal-pagination {
  display: flex;
  justify-content: flex-end;
}

.meal-ing-list {
  cursor: pointer;
  border-radius: 37px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 6px 0px;
  z-index: 0;
  position: relative;
  background: transparent !important;
}

.meal-ing-list td:first-child {
  background-color: transparent !important;
}

.meal-ingredients-table{
  white-space: nowrap !important;
  border-collapse: initial !important;
  border-spacing: 0 17px !important;
}

.meal-name-select .css-13cymwt-control {
  border-radius: 10px !important;
  border: 0.1px solid #e0e0e0 !important;
  height: 43px !important;
  &:focus {
    box-shadow: none;
  }
}

.input-border {
  div {
    .css-13cymwt-control {
      border-radius: 10px !important;
      border: 0.1px solid #e0e0e0 !important;
      height: 43px !important;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.attach-a-pos {
  .css-13cymwt-control{
    border-radius: 10px !important;
    border: 0.1px solid #e0e0e0 !important;
    height: 43px !important;
    &:focus {
      box-shadow: none;
    }
  }
}

.food-cost-card {
  width: 200px;
  border-radius: 16px;
  padding: 10px;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.food-cost-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.gauge-container {
  position: relative;
  width: 120px;
  height: 60px;
  margin: 0 auto;
}

.gauge-text {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.info-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #873CFC;
  font-size: 14px;
  cursor: pointer;
}
